input:focus{
  outline: none;
}

button:focus{
  outline: none;
}

* {
  font-family: arial
}
